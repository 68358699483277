<template>
  <div id="donation" class="template-1">
    <header class="page-header container">
      <h1>{{ translations.tcMakeAGift }}</h1>
    </header>
    <div class="container" v-show="!donationDetailFormVisible">
      <b-row>
        <b-col cols="0" sm="2"></b-col>
        <b-col cols="12" sm="8">
          <b-row>
            <b-col xs="12" sm="6">
              <div class="total">{{ translations.tcTotalDonations }} </div>
            </b-col>
            <b-col xs="12" sm="6" class="total-donation amount">
              <div class="total">{{ donationItemsTotal | toCurrency }}</div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div id="donation-payment-input" class="col-sm-8 m-auto">
        <h4 class="donor-header">{{ translations.tcDonorInformation }}</h4>
        <form ref="form">
          <b-container fluid class="text-light text-left">
            <div class="pt-5">
              <div class="donor-info">
                <b-row>
                  <b-col sm="12">
                    {{ userDemograph.first_name }}<span class="error-label" v-if="userDemograph.first_name == ''">
                      {{ translations.tcFirstNameNeeded }}</span>
                    {{ userDemograph.last_name }}<span class="error-label" v-if="userDemograph.last_name == ''">
                      {{ translations.tcLastNameNeeded }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">{{ userDemograph.address1 }}<span class="error-label"
                      v-if="userDemograph.address1 == ''"> {{ translations.tcAddressNeeded }}</span> {{
                        userDemograph.address2 }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="10">
                    {{ userDemograph.city }}<span class="error-label" v-if="userDemograph.city == ''">
                      {{ translations.tcCityNeeded }}</span>,
                    {{ userDemograph.state }}<span class="error-label" v-if="userDemograph.state == ''">
                      {{ translations.tcStateNeeded }}</span>
                    <span v-if="isUSSelected"> {{ userDemograph.postal_code }}<span class="error-label"
                        v-if="userDemograph.postal_code == ''"> {{ translations.tcZipNeeded }}</span></span></b-col>
                  <b-col sm="10"></b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">{{ userCountryObject.name }}<span class="error-label"
                      v-if="userCountryObject.name == ''"> {{ translations.tcCountryNeeded }}</span></b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">{{ userDemograph.email }}<span class="error-label" v-if="userDemograph.email == ''">
                      {{ translations.tcEmailNeeded }}</span></b-col>
                </b-row>
              </div>
              <b-form>
                <!-- CC -->
                <b-form-group :label="translations.tcPaymentType" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="btn-radios-1" class="paymentType" button-variant="outline-light" size="sm"
                    v-model="form.paymenttype" :options="optionsPaymentTypes" :aria-describedby="ariaDescribedby"
                    name="radios-btn-default" buttons></b-form-radio-group>
                </b-form-group>
                <template v-if="form.paymenttype === 'CCD'">
                  <b-row v-if="!!donorCardsOnFile[0]">
                    <b-form-group class="form-element col-sm-5">
                      <div class="form-div">
                        <div class="label-strong" v-if="!userImpersonation">Choose from these cards</div>
                        <CreditCardsOnFile :PaymentInstruments="donorCardsOnFile"
                          @handleCardSelection="handleCardSelection($event)" />
                      </div>
                    </b-form-group>
                  </b-row>
                  <b-row v-if="!!donorCardsOnFile[0]">
                    <div class="label-strong" style="margin-left: 17px;">
                      <input type="radio" name="avcard" class="mb-2 mr-sm-2 mb-sm-0" :checked="isNewCCSelected"
                        @change="handleCardNumberClick()" id="label100" />&nbsp;
                      <label for="label100" v-if="!userImpersonation">or, enter a new Credit Card</label>
                    </div>
                  </b-row>
                  <b-row v-if="isNewCCSelected">
                    <b-form-group class="form-element col-sm-5">
                      <b-form-input class="mb-2 mr-sm-2 mb-sm-0" id="cc-input" type="number" min="0"
                        @keypress="isNumber($event)" :placeholder="translations.tcCCNum" v-model="form.ccnum"
                        :state="(form.ccnum !== null && form.ccnum !== '' && isValidCreditCard(form.ccnum))"
                        :required="form.paymenttype === 'CCD' && !onFileCardSelected"
                        @click="handleCardNumberClick()"></b-form-input>
                    </b-form-group>
                    <b-form-group class="form-element col-sm-2">
                      <b-form-input class="mb-2 mr-sm-2 mb-sm-0" id="cvv-input" type="number"
                        :placeholder="translations.tcCVVCode" v-model="form.cvv"
                        :state="form.cvv !== null && form.cvv !== '' && isValidCVV(form.ccnum, form.cvv)"
                        :required="form.paymenttype === 'CCD' && !onFileCardSelected"></b-form-input>
                    </b-form-group>
                    <b-form-group class="form-element col-sm-2">
                      <b-form-input id="input-month" @blur="formatMonth()" v-model="form.expiremonth"
                        :placeholder="translations.tcMonth" type="number" min="1" max="12" class="mb-2 mr-sm-2 mb-sm-0"
                        :state="form.expiremonth !== null && form.expiremonth !== '' && form.expiremonth < 13 && form.expiremonth > 0 && form.expiremonth.length == 2"
                        :required="form.paymenttype === 'CCD' && !onFileCardSelected">
                        <template slot="first"></template>
                      </b-form-input>
                    </b-form-group>
                    <b-form-group class="form-element col-sm-3">
                      <b-form-input v-model="form.expireyear" :placeholder="translations.tcYear"
                        class="mb-2 mr-sm-2 mb-sm-0" type="number" max="4" min="0"
                        :state="form.expireyear !== null && form.expireyear !== '' && form.expireyear.length == 4"
                        :required="form.paymenttype === 'CCD' && !onFileCardSelected">
                        <template slot="first"></template>
                      </b-form-input>
                    </b-form-group>
                  </b-row>
                </template>
                <!-- ACH -->
                <template v-if="form.paymenttype === 'ACH'">
                  <b-row v-if="!!donorChecksOnFile[0]">
                    <b-form-group class="form-element col-sm-5">
                      <div class="form-div">
                        <div class="label-strong">Choose from these accounts</div>
                        <CreditCardsOnFile :PaymentInstruments="donorChecksOnFile"
                          @handleCardSelection="handleAccountSelection($event)" />
                      </div>
                    </b-form-group>
                  </b-row>
                  <b-row v-if="!!donorChecksOnFile[0]">
                    <div class="label-strong" style="margin-left: 17px;">
                      <input type="radio" name="avcard" class="mb-2 mr-sm-2 mb-sm-0" :checked="isNewAccountSelected"
                        @change="handleCheckAccountClick()" id="label100" />&nbsp;
                      <label for="label100">or, enter a new bank account</label>
                    </div>
                  </b-row>
                  <b-row v-if="isNewAccountSelected">
                    <b-form-group class="form-element">
                      <b-form-input v-model="form.routingnumber" class="mb-2 mr-sm-2 mb-sm-0" style="min-width: 500px;"
                        :state="form.routingnumber !== null && form.routingnumber !== ''"
                        :placeholder="translations.tcBankRoutingNumber"
                        :required="form.paymenttype === 'ACH'"></b-form-input>
                    </b-form-group>
                    <b-form-group class="form-element">
                      <b-form-input v-model="form.checkingnumber" class="mb-2 mr-sm-2 mb-sm-0" style="min-width: 500px;"
                        :placeholder="translations.tcBankAccountNumber"
                        :state="form.checkingnumber !== null && form.checkingnumber !== ''"
                        :required="form.paymenttype === 'ACH'"></b-form-input>
                    </b-form-group>
                    <b-form-group class="form-element">
                      <b-form-input v-model="form.checkingnumber2"
                        :placeholder="translations.tcReEnterBankAccountNumber" class="mb-2 mr-sm-2 mb-sm-0"
                        style="min-width: 500px;" :state="form.checkingnumber2 !== null && form.checkingnumber2 !== ''"
                        :required="form.paymenttype === 'ACH'"></b-form-input>
                    </b-form-group>
                  </b-row>
                </template>
                <!-- /ACH -->
                <b-row>
                  <div class="col-sm-12">
                    <b-form-group :label="translations.tcDonationType" v-slot="{ ariaDescribedby }"
                      style="display: inline-block">
                      <b-form-radio-group v-model="form.donationfrequency">
                        <b-form-radio :aria-describedby="ariaDescribedby" name="donationfrequency" value=0>{{
                          translations.tcOneTimeDonation }}</b-form-radio>
                        <b-form-radio :aria-describedby="ariaDescribedby" name="donationfrequency" value=1>{{
                          translations.tcMonthlyDonation }}</b-form-radio>
                      </b-form-radio-group>
                      <div style="display:flex;" class="mt-3" v-if="form.donationfrequency == 1">
                        <b-form-checkbox v-model="form.recurringConfirmed" style="font-size: 12px;">{{
                          translations.tcRecurringTermsAndConditions }}</b-form-checkbox>

                        <span class="i-tooltip ml-2" v-b-tooltip.hover
                          :title="translations.tcRecurringTermsAndConditionsText">
                          <i-tooltip />
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </b-row>
              </b-form>
            </div>
          </b-container>
        </form>
        <b-row class="action-btn-row">
          <b-col class="actionBtns">
            <b-button class="donation-btn btn btn-tertiary col-sm-6" @click="handleCancelPayment">{{
              translations.tcCancel
              }}</b-button>
          </b-col>
          <b-col class="actionBtns">
            <b-button class="donation-btn btn btn-primary col-sm-6" @click="prepareDonationSummary()">
              {{ translations.tcProcess }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-show="donationDetailFormVisible">
      <b-form class="formClass">
        <div class="container totals-section">
          <vue-sticky-element stickMode="element-start">
            <div class="container">
              <b-row>
                <b-col cols="0" sm="2"></b-col>
                <b-col cols="12" sm="8">
                  <div class="container total-section-container">
                    <b-row>
                      <b-col xs="12" sm="6">
                        <div class="total">{{ translations.tcTotalDonations }}</div>
                      </b-col>
                      <b-col xs="12" sm="6" class="total-donation amount">
                        <div class="total">{{ donationItemsTotal | toCurrency }}</div>
                      </b-col>
                    </b-row>
                    <br />
                    <div class="text-center">
                      <label class="error-label" v-if="maxDonationExceeded || minDonationNotMet">{{ minMaxMessage
                        }}</label>
                    </div>
                    <b-row class="action-btn-row">
                      <b-col class="actionBtns">
                        <b-button class="donation-btn btn-tertiary col-sm-4" @click="handleCancelDonationClick">
                          {{ translations.tcCancel }}
                        </b-button>
                      </b-col>
                      <b-col class="actionBtns">
                        <b-button class="donation-btn btn-tertiary col-sm-4" @click="commenceDonationCleanUp()">
                          {{ translations.tcClear }}
                        </b-button>
                      </b-col>
                      <b-col class="actionBtns">
                        <b-button :disabled="maxDonationExceeded || minDonationNotMet"
                          class="donation-btn btn-primary col-sm-4" @click="preparePaymentScreen">
                          {{ translations.tcSubmit }}
                        </b-button>
                      </b-col>
                    </b-row>
                    <br />
                  </div>
                </b-col>
                <b-col cols="0" sm="2"></b-col>
              </b-row>
            </div>
          </vue-sticky-element>
        </div>
        <div class="container">
          <div id="donation-input" class="col-sm-8 m-auto">
            <div>
              <div class="" v-for="(item, idx) in donationItems" :key="idx">
                <b-row :class="[item.childItem == 1 || idx == 0 ? '' : 'row-item']" class="donation-items">
                  <b-col xs="12" sm="8">
                    <b :class="[item.childItem == 1 ? 'child-menu-item' : '']">{{ translations[item.category] }}</b>
                    <div class="child-menu-item" style="font-size:13px;" v-html="translations[item.text]"></div>
                  </b-col>
                  <b-col xs="12" sm="4" v-if="item.displayOnly == 0">
                    <div class="dollarsign">$</div>
                    <b-form-input type="text" class="currency-input" size="sm" @click="handleAmountInputClick($event)"
                      @blur="handleAmountInputLeave($event)" @change="handleLineItemChange($event, item.category, idx)"
                      @keypress="onlyNumber($event)" @keyup="handleLeadingDecimalEntry($event)"
                      v-model="item.amount"></b-form-input>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
    <add-donation-summary :i18n="translations.components" :translations="translations"
      @success="processPayment($event)"></add-donation-summary>
    <div style="height: 40px">&nbsp;</div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'
import { numericMixin } from '@/mixins/numericMixin'
import { alertMixin } from '@/mixins/alertMixin'
import AddDonationSummary from '@/components/AddDonationSummary.vue'
import constantData from '@/json/data.json'
import CreditCardsOnFile from "@/components/CreditCardsOnFile.vue";
import iTooltip from '@/assets/svgs/i-tooltip.vue'

export default {
  name: 'donations',
  mixins: [translationMixin, numericMixin, alertMixin],
  data() {
    return {
      donationDetailFormVisible: true,
      donationItems: [],
      form: {
        ccnum: '',
        checkingnumber: '',
        checkingnumber2: '',
        cnt_key: '',
        cvv: '',
        donationfrequency: "0",
        expiremonth: '',
        expireyear: '',
        paymentinstrument: '0',
        paymenttype: "CCD",
        recurringConfirmed: false,
        routingnumber: ''
      },
      minMaxMessage: '',
      monthTranslated: 'MM',
      noCloseOnBackdrop: true,
      optionsPaymentTypes: [
        { value: 'CCD', text: 'CC' },
        { value: 'ACH', text: 'ACH' },
      ],
      onFileCardSelected: null,
      onFileAccountSelected: null,
      selectedDonationItems: [],
      stateTranslated: 'State',
      total: 0.0,
      translations: {},
      yearTranslated: 'YYYY',
    }
  },
  async applyFoundPerson(evt) {
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      donationModel: 'donationModule/donationModel',
      donationsSummary: 'donationModule/donationsSummary',
      donorCardsOnFile: 'user/donorCardsOnFile',
      donorChecksOnFile: 'user/donorChecksOnFile',
      paymentInfo: 'membership/paymentInfo',
      prefCulture: 'user/userPreferredCulture',
      userCountryObject: 'user/userCountryObject',
      userDemograph: 'user/demograph',
      userImpersonation: 'user/userImpersonation',
      userPreferredCulture: 'user/userPreferredCulture'
    }),
    donationItemsTotal() {
      this.total = 0.0
      this.donationItems.map((xl) => {
        xl.amount = parseFloat(xl.amount).toFixed(2) === 'NaN' ? 0 : xl.amount
        this.total += parseFloat(xl.amount)
      })
      return this.total.toFixed(2)
    },
    isNewCCSelected() {
      return this.onFileCardSelected === null
    },
    isNewAccountSelected() {
      return this.onFileAccountSelected === null
    },
    isUSSelected() {
      return this.form.cnt_key === null || this.form.cnt_key.toLowerCase() === this.USA_country
    },
    maxDonationExceeded() {
      this.minMaxMessage = this.translations.tcExceededMaxAmount
      return this.maxAmtExceeded(this.total.toFixed(2))
    },
    minDonationNotMet() {
      this.minMaxMessage = this.translations.tcAmtBelowRequirement
      return this.total < 1 && this.total > 0
    },
    USA_country() {
      return constantData.USA_country
    }
  },
  methods: {
    ...mapActions({
      loadDonorCardsOnFile: 'user/loadDonorCardsOnFile',
      processDonationPayment: 'donationModule/processDonationPayment',
      saveAutopay: 'membership/saveAutopay',
      setDonationItems: 'donationModule/setDonationItems',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAccountOnFile: 'user/setSelectedAccountOnFile',
      setSelectedCardOnFile: 'user/setSelectedCardOnFile'
    }),
    async page_load() {
      try {
        await Promise.all([
          await this.getViewTranslations(),
          await this.getComponentTranslations(
            'add-summary'
          ),
        ]).then((results) => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
        })
        this.donationItems = this.donationModel.transactions
        this.form.donationfrequency = 0
      } catch (e) {
        console.error(e)
      }
    },
    async commenceDonationCleanUp() {
      this.donationItems.map((l) => {
        l.amount = 0.0
      })
      await this.setDonationItems([])
    },
    handleCardSelection(evt) {
      this.form.ccnum = ''
      this.form.expiremonth = ''
      this.form.expireyear = ''
      this.form.cvv = ''
      this.onFileCardSelected = evt
      this.onFileAccountSelected = null
      this.setSelectedCardOnFile(evt.pit_last_four)
    },
    handleCardNumberClick() {
      this.onFileCardSelected = null
      this.setSelectedCardOnFile(null)
    },
    handleAccountSelection(evt) {
      this.form.checkingnumber = ''
      this.form.routingnumber = ''
      this.onFileCardSelected = null
      this.onFileAccountSelected = evt
      this.setSelectedAccountOnFile(evt.pit_last_four)
    },
    handleCheckAccountClick() {
      this.onFileAccountSelected = null
      this.setSelectedAccountOnFile(null)
    },
    handleAmountInputLeave(evt) {
      if (evt.target.value === '') {
        evt.target.value = '0.00'
      }
    },
    handleCancelDonationClick() {
      this.commenceDonationCleanUp()
      this.$router.go(-1)
    },
    handleLineItemChange(evt, cat, idx) {
      this.donationItems.map((l, nx) => {
        l.amount = (l.amount * 1.0).toFixed(2)
      })
    },
    async preparePaymentScreen() {
      this.selectedDonationItems = []
      this.summaryCounts = []
      this.donationItems.map((item) => {
        if (item.amount > 0.0) {
          this.selectedDonationItems.push({
            amount: item.amount,
            campaignCode: item.campaignCode,
            category: item.category,
            field: item.field,
            fundCode: item.fundCode,
            sourceCode: item.sourceCode
          })
        }
      })
      if (this.selectedDonationItems.length > 0) {
        await this.setDonationItems(this.selectedDonationItems)
        await this.loadDonorCardsOnFile({ ind_key: this.userDemograph.ind_key, origin: 'TheConnection', check: true })
        await this.showPaymentForm()
      }
    },
    async showPaymentForm() {
      this.form.cnt_key = this.userCountryObject.key
      this.monthTranslated = this.translations.tcMonth
      this.optionsPaymentTypes[0].text = this.translations.tcCreditCard
      this.optionsPaymentTypes[1].text = this.translations.tcACH
      this.stateTranslated = this.translations.tcState
      this.yearTranslated = this.translations.tcYear
      this.donationDetailFormVisible = false
      this.scrollUpToTop()
    },
    async closeOutPaymentForm(evt) {
      this.commencePaymentCleanUp()
      await this.commenceDonationCleanUp()
    },
    commencePaymentCleanUp() {
      this.donationDetailFormVisible = true
      this.form.ccnum = ''
      this.form.checkingnumber = ''
      this.form.checkingnumber2 = ''
      this.form.cvv = ''
      this.form.donationfrequency = "0"
      this.form.expiremonth = ''
      this.form.expireyear = ''
      this.form.recurringConfirmed = false
      this.form.routingnumber = ''
      this.scrollUpToTop()
    },
    handleCancelPayment() {
      this.scrollUpToTop()
      this.donationDetailFormVisible = true
    },
    async prepareDonationSummary() {
      this.scrollUpToTop()
      let errorMessage = this.translations.tcErrorCorrectionMessage + "<br>"
      let errorCount = 0
      if (
        !this.userDemograph.first_name ||
        !this.userDemograph.last_name ||
        !this.userDemograph.address1 ||
        !this.userDemograph.city ||
        !this.userDemograph.state ||
        !this.userDemograph.postal_code ||
        !this.userDemograph.email ||
        !this.userCountryObject.name
      ) {
        errorCount++
        errorMessage += this.translations.tcDonorDetailMissing + '<br>'
      }
      if ((
        (this.form.paymenttype === 'CCD' && !this.isValidCreditCard(this.form.ccnum)) ||
        (this.form.paymenttype === 'CCD' && (!this.form.expiremonth || this.form.expiremonth > 13 || this.form.expiremonth < 1)) ||
        (this.form.paymenttype === 'CCD' && (!this.form.expireyear || !this.form.expireyear.length === 4)) ||
        (this.form.paymenttype === 'CCD' && !this.isValidCVV(this.form.ccnum, this.form.cvv)) ||
        (this.form.paymenttype === 'ACH' && !this.form.routingnumber) ||
        (this.form.paymenttype === 'ACH' && !this.form.checkingnumber) ||
        (this.form.paymenttype === 'ACH' && !this.form.checkingnumber2)) && (!this.onFileCardSelected && !this.onFileAccountSelected)
      ) {
        errorCount++
        errorMessage += this.translations.tcErrorAllFieldsRequired + '<br>'
      }
      if (this.form.paymenttype === 'CCD' && !this.onFileCardSelected) {
        var expDate = new Date(this.form.expireyear, this.form.expiremonth, '01')
        if (expDate < new Date()) {
          errorCount++
          errorMessage += this.translations.tcErrorInvalidExpDate + '<br>'
        }
        this.form.routingnumber = ''
        this.form.checkingnumber = ''
        this.form.checkingnumber2 = ''
      } else {
        if (this.form.paymenttype === 'ACH' && !this.onFileAccountSelected) {
          if (this.form.checkingnumber !== this.form.checkingnumber2) {
            errorCount++
            errorMessage += this.translations.tcErrorCheckingNumsDoNotMatch + '<br>'
          }
          this.form.ccnum = ''
          this.form.expiremonth = ''
          this.form.expireyear = ''
          this.form.cvv = ''
        }
      }

      if (this.form.recurringConfirmed == false && this.form.donationfrequency == "1") {
        errorCount++
        errorMessage += this.translations.tcErrorAcceptTermsOfRecurringDonation + "<br>"
      }
      if (errorCount > 0) {
        this.$swal({
          icon: 'error',
          html: errorMessage,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
        return
      } else {
        //show the donation summary
        this.showModal(null, 'addDonationSummaryModal')
      }
    },
    showModal(e, modalName) {
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async processPayment(evt) {
      var payload = {
        address1: this.userDemograph.address1,
        address2: this.userDemograph.address2,
        cardcvv: this.form.cvv,
        cardnumber: this.form.ccnum,
        checkingnumber: this.form.checkingnumber,
        city: this.userDemograph.city,
        country: this.userCountryObject.name,
        cstkey: this.userDemograph.ind_key,
        donationItems: this.donationsSummary,
        email: this.userDemograph.email,
        expiremonth: this.form.expiremonth,
        expireyear: this.form.expireyear,
        firstname: this.userDemograph.first_name,
        lastname: this.userDemograph.last_name,
        mbrkey: this.userDemograph.member_number,
        PaymentInstrumentID:
          (this.form.paymenttype === 'CCD' && !!this.onFileCardSelected ? this.onFileCardSelected.pit_instrument_id :
            (this.form.paymenttype === 'ACH' && !!this.onFileAccountSelected ? this.onFileAccountSelected.pit_instrument_id :
              null)),
        paymenttype: this.form.paymenttype,
        recurring: this.form.donationfrequency == 1,
        recurrenceType: this.form.donationfrequency,
        routingnumber: this.form.routingnumber,
        state: this.userDemograph.state,
        userPreferredCulture: this.userPreferredCulture,
        zip: this.userDemograph.postal_code
      }
      try {
        let response = ''
        let errorMsg = ''
        await Promise.all([(this.setLoadingStatus(true), response = await this.processDonationPayment(payload))]).then(() => {
          this.setLoadingStatus(false)
          if (response == true) {
            this.$swal({
              icon: 'success',
              text: this.translations.tcPaymentSubmitted,
              confirmButtonText: this.translations.tcOk || 'Ok',
            })
              .then((result) => {
                this.closeOutPaymentForm()
              })
          } else if (response == null) {
            errorMsg = this.translations.tcErrorProcessingPaymentContactCustService
          }
          else if (response.success == false) {
            console.error(response.error)
            errorMsg = this.translations.tcErrorDuringSave
          } else {
            errorMsg = this.translations.tcErrorProcessingPaymentContactCustService
          }
          if (errorMsg) {
            this.$swal({
              icon: 'error',
              text: errorMsg,
              confirmButtonText: this.translations.tcOk || 'Ok',
            }).then((result) => {
              return
            })
          }

        })
      } catch (e) {
        console.error(e)

      } finally {
        this.setLoadingStatus(false)
      }
    },
    formatMonth() {
      if (this.form.expiremonth.length == 1) {
        this.form.expiremonth = "0" + this.form.expiremonth;
      }
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    scrollUpToTop() {
      const elmnt = document.getElementById('nav')
      elmnt.scrollIntoView()
    },
  },
  components: {
    CreditCardsOnFile: CreditCardsOnFile,
    iTooltip: iTooltip,
    'add-donation-summary': AddDonationSummary
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.dollarsign {
  position: relative;
  height: 0;
  left: -20px;
  font-size: 25px;
  top: -3px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.donation-items {
  padding-bottom: 10px;
  padding-top: 10px;
}

.row-item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#donation-input {
  background: #fcfcfc;
  padding: 25px;
  border-radius: 5px;
}

#donation-payment-input {
  background: #fcfcfc;
  padding-bottom: 75px;
  border-radius: 5px;
}

.total-donation div {
  font-weight: bold;
  text-align: center;

  @include breakpoint(sm) {
    text-align: left;
  }
}

.currency-input {
  width: 80%;
  display: inline-block;
  border-radius: 4px;

  @include breakpoint(sm) {
    width: 100%;
  }
}

.total {
  font-size: 25px;
  text-align: center;

  @include breakpoint(sm) {
    text-align: left;
  }
}

.total-section-container {
  background: #fcfcfcfc;
  border: 3px solid #80808080;
  padding-top: 25px;
  margin-bottom: 40px;
}

.donation-btn {
  min-width: 140px;
  border-radius: 4px;
}

.actionBtns {
  text-align: center;
}

.vue-sticky-element {
  z-index: 1000;
  background: #f5f5f5;
}

.vue-sticky-element--stuck {
  transform: translateY(0) !important;
  border-radius: 5px;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-left {
  width: 75%;
}

.flex-right {
  width: 25%;
}

.new-row {
  display: inline-block;
  margin-bottom: 10px;
}

.label-strong {
  font-weight: bold;
}

.child-menu-item {
  padding-left: 25px;
  box-decoration-break: clone;

  @include breakpoint(sm) {
    padding-left: 0px;
  }
}

/*This setting is to hide the up/down arrows, in web browsers, on the number input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control {
  font-weight: 400;
  border-radius: 0.25rem;
}

.custom-control-label {
  padding-top: 3px;
}

.g-select {
  font-weight: 400 !important;
}

.donor-header {
  text-align: center;
  color: grey;
  padding-top: 25px;
}

.donor-info {
  margin-bottom: 20px;
  border-bottom: 2px solid #f5f5f5;
  font-weight: bold;
}

.donor-info div {
  margin-bottom: 3px;
}

.donor-info .row:last-child {
  margin-bottom: 20px;
}

svg {
  vertical-align: super !important;
}

.i-tooltip {
  vertical-align: super;

  svg path {
    fill: #000 !important;
  }
}

.error-label {
  color: red;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: lightgrey !important;
}

@media (max-width: 400px) {
  .totals-section {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}

@media (max-width: 700px) {
  .donation-btn {
    min-width: 126px !important;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 0px;
  }

  .new-row {
    display: none !important;
  }
}
</style>
<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.paymentType {
  border: 1px solid grey;
  border-radius: 5px;

  .btn.btn-light {
    color: grey;
    background-color: white;
  }

  .btn-outline-light:not(:disabled):not(.disabled):active,
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .show>.btn-outline-light.dropdown-toggle {
    color: white !important;
    background-color: grey;

  }

  .btn.btn-outline:hover,
  .btn.btn-outline-light:hover {
    color: white;
    background-color: grey;
  }

  .btn-sm,
  .btn-group-sm>.btn {
    line-height: 0;
    font-weight: 600;
  }
}
</style>